<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        class="d-flex justify-content-between flex-column md-6"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <h4>Candidate Details:</h4>
          </b-col>
          <b-col
            cols="12"
            md="6"
          />
        </b-row>
        <table class="w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Name</span>
            </th>
            <td class="pb-50">
              {{ userData.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ userData.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Position</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.position }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Mobile</span>
            </th>
            <td>
              {{ userData.mobile_number }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Date Of Birth</span>
            </th>
            <td>
              {{ userData.date_of_birth }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Year Of Experience</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.years_of_experience }}
            </td>
          </tr>
        </table>
      </b-col>
      <b-col
        class="d-flex justify-content-between flex-column md-6"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
            v-if="userData.resume_file_path"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              class="ml-2"
              @click.stop.prevent="openWindow(userData.resume_file_path)"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-25"
              />
              <span>View Resume</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="ml-2"
              :to="{ name: 'all-view-nhr', params: { id: $route.params.nhrId }}"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                class="mr-25"
              />
              <span>Back To New Hire Request</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <table class="w-100 mt-2">
            <tr>
              <th class="pb-50">
                <img src="@/assets/images/icons/file-icons/rupee.png" height="13px" class="mr-1">
                <span class="font-weight-bold">CTC</span>
              </th>
              <td class="pb-50">
                {{ userData.ctc }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <img src="@/assets/images/icons/file-icons/rupee.png" height="13px" class="mr-1">
                <span class="font-weight-bold">Expected CTC</span>
              </th>
              <td class="pb-50">
                {{ userData.expected_ctc }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Notice Period</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.notice_period }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="GlobeIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Current Company</span>
              </th>
              <td>
                {{ userData.current_company }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="GlobeIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Previous Company</span>
              </th>
              <td>
                {{ userData.previous_company }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="FileIcon"
                  class="mr-75"
                />
                <span class="font-weight-bold">Skills</span>
              </th>
              <td>
                {{ userData.skills }}
              </td>
            </tr>
          </table>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BRow, BCol, BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openWindow(link) {
      window.open(link)
    },
  },
}
</script>

<style>

</style>
