<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userCandidateData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this candidate id. Check
        <b-link
          class="alert-link"
          :to="{ url: '/all/view/nhr/' + $route.params.candidateId }"
        >
          nhr
        </b-link>
      </div>
    </b-alert>

    <template v-if="userCandidateData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <candidate-view-info :user-data="userCandidateData" />
        </b-col>
      </b-row>
      <b-card>
        <b-tabs>
          <b-tab active title="Candidate Status/Timeline">
            <candidate-view-timeline
              :history-data="userCandidateData.histories"
            />
          </b-tab>
          <b-tab title="Assigned Interviews">
            <b-card-text>
              <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                  <b-form-group>
                    <div class="d-flex align-items-center">
                      <label class="mr-1">btn Search</label>
                      <b-form-input
                        v-model="searchTerm"
                        placeholder="Search"
                        type="text"
                        class="d-inline-block"
                      />
                    </div>
                  </b-form-group>
                </div>

                <!-- table -->
                <vue-good-table
                  :columns="columns"
                  :rows="userCandidateData.interviews"
                  :rtl="direction"
                  :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                  }"
                  :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                  }"
                >
                  <template slot="table-row" slot-scope="props">
                    <!-- Column: Action -->
                    <span v-if="props.column.field === 'action'">
                      <span>
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                            />
                          </template>
                          <b-dropdown-item
                            @click="viewInterview(props.row.hashid)"
                          >
                            <feather-icon icon="EyeIcon" class="mr-50" />
                            <span>View</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Showing 1 to </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['3', '5', '10']"
                          class="mx-1"
                          @input="
                            (value) =>
                              props.perPageChanged({ currentPerPage: value })
                          "
                        />
                        <span class="text-nowrap">
                          of {{ props.total }} entries
                        </span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="
                            (value) => props.pageChanged({ currentPage: value })
                          "
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import candidateStoreModule from "../../../../candidates/candidateStoreModule";
import CandidateViewInfo from "./CandidateViewInfo.vue";
import CandidateViewTimeline from "./CandidateViewTimeline.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    CandidateViewInfo,
    CandidateViewTimeline,
    VueGoodTable,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  setup() {
    const userCandidateData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-candidates";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, candidateStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch(
        "app-candidates/fetchCandidate",
        router.currentRoute.params.candidateId
      )
      .then((response) => {
        userCandidateData.value = response.data.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userCandidateData.value = undefined;
        }
      });

    return {
      userCandidateData,
    };
  },
  data() {
    return {
      pageLength: 3,
      pageLengthEmployee: 3,
      dir: false,
      columns: [
        {
          label: "Interview Title",
          field: "title",
        },
        {
          label: "Start At",
          field: "start_at",
        },
        {
          label: "End At",
          field: "end_at",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    viewInterview(id) {
      this.$router.push(`/view/interview/${id}`).catch(() => {});
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
